/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-16 01:02:43
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-03-21 13:23:29
 */
import request from '@/request/request'
// 电梯年检
// 列表
export function all(data) {
    return request({
        url:'/Property/AnnualInspection/List',
        method:'post',
        data
    })
}
// 详细数据
export function detail(data){
    return request({
        url:'/Property/AnnualInspection/Info',
        method:'post',
        data
    })
}

